import React from 'react';
import styled from 'styled-components';
import { LinkButton } from 'components/link-button';
import { NonStretchedImage } from 'components/non-stretched-image';
import { SectionTitle } from 'components/section-title';
import { SectionWrapper } from 'components/section-wrapper';
import { PATHS } from 'constants/paths';
import { SECTIONS } from 'constants/sections';
import { graphql, useStaticQuery } from 'gatsby';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';

const SContainer = styled.div`
  background: ${COLORS.LIGHT_GREY};
  display: flex;

  ${MEDIA.max768`
    flex-direction: column-reverse
`};
`;

const STextColumn = styled.div`
  padding: 3.75rem;
  padding-left: 2rem;

  ${MEDIA.max520`
   padding: 1.75rem;
`};
`;

const SParagraph = styled.p`
  color: ${COLORS.GREY};
`;

const SPopularOffersText = styled.p`
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
`;

const SAllOffersLink = styled(LinkButton)`
  margin-top: 2rem;
  margin-bottom: 2.5rem;
`;

const SOffersButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  ${MEDIA.max520`
    grid-template-columns: 1fr;
`};
`;

const SOfferLink = styled.a`
  width: auto;
  color: ${COLORS.BLACK};
  height: 50px;
  text-align: center;
  font-size: 18px;
  border: 2px solid ${COLORS.CREAMY};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Abhaya Libre', serif;
  font-weight: bold;
`;

const selectedOffers = [
  {
    text: 'Usuwanie zmarszczek',
    href: 'usuwanie-zmarszczek-mimicznych-bot',
  },
  {
    text: 'Zabieg z Kwasem Polimlekowym',
    href: 'kwas-polimlekowy-sculptra',
  },
];

export const OfferSection = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "zabiegi-banner.png" }) {
        childImageSharp {
          gatsbyImageData(width: 634, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <SectionWrapper id={SECTIONS.OFFER.slice(2)}>
      <SContainer>
        <NonStretchedImage childImageSharp={data.desktop.childImageSharp} />
        <STextColumn>
          <SectionTitle title="Zabiegi Medycyny Estetycznej" />
          <SParagraph>
            Zabiegi medycyny estetycznej w IDEALE, skutecznie poprawiają wygląd
            oraz kondycję skóry, wykorzystując sprawdzone metody takie jak
            btx, wypełnienia kwasem hialuronowym, zabiegi ze stymulatorami
            tkankowymi oraz mezoterapię.
          </SParagraph>
          <SAllOffersLink to={PATHS.OFFER} text="Zobacz pełną ofertę" full />
          <SPopularOffersText>NAJPOPULARNIEJSZE ZABIEGI:</SPopularOffersText>
          <SOffersButtons>
            {selectedOffers.map((item) => (
              <SOfferLink
                key={item.text}
                target="_blank"
                href={`/oferta/${item.href}`}
              >
                {item.text}
              </SOfferLink>
            ))}
          </SOffersButtons>
        </STextColumn>
      </SContainer>
    </SectionWrapper>
  );
};
