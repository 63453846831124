import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/colors';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';

const SMainTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
`;

const SMainText = styled.div`
  z-index: ${STYLES_CONSTANTS.Z_INDEX.HIGH};
`;

const STitle = styled.h1`
  font-weight: bold;
  font-size: 8.1rem;
  margin-bottom: 0;
  width: 72vw;
  word-break: break-word;
  line-height: 0.9;
  font-family: 'Abhaya Libre', serif;
  ${MEDIA.max1600`
      font-size: 6.5rem;
    `}
  ${MEDIA.max1024`
      font-size: 4.6rem;
    `}
    ${MEDIA.max420`
      font-size: 3.5rem;
    `}
`;

const SDescriptionWrapper = styled.div`
  margin: 2rem 0;
`;

const SDescription = styled.p`
  font-size: 1em;
  color: ${COLORS.GREY};
  width: 35vw;
  ${MEDIA.max1024`
    font-size: 1em;
    width: 50vw;
  `}
  ${MEDIA.max420`
    width: 60vw;
  `}
`;

export const MainText = () => {
  return (
    <SMainTextContainer>
      <SMainText>
        <STitle>Podkreślamy</STitle>
        <STitle>Twoje Piękno</STitle>
        <SDescriptionWrapper>
          <SDescription>
            Wierzymy, że dobry i systematycznie realizowany plan anti-aging
            procentuje zadbaną i promienną skórą. Pragniemy aby nasze pacjentki
            poczuły się piękne we własnym ciele.
          </SDescription>
        </SDescriptionWrapper>
      </SMainText>
    </SMainTextContainer>
  );
};
